img {
    /* Set a maximum width of 500 pixels when the img width exceeds 500px */
    max-width: "100%";
    /* Automatically adjust the height to maintain the aspect ratio */
    height: auto;
}

video{
    max-width: auto;
}
