.NavContainer {
  width: 100vw;
  height: 100px;
  background: rgb(91, 91, 91);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.NavContainer a {
  color: white;
  text-decoration: none;
}

.centered {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  position: relative;
  gap: 5px;
}

.MuiCard-root{
  display: flex;
  flex-direction: column;
}
.MuiCardActions-root{
   margin-top: auto;
}

.iframe {
border: 1;
border-style: solid;
border-color: lightgrey;
}