img {
    /* Set a maximum width of 500 pixels when the img width exceeds 500px */
    max-width:100%;
    /* Automatically adjust the height to maintain the aspect ratio */
    height: auto;
}
/* video {
    max-width: 70%;
} */
@media (max-width: 499px) {
    video {
      /* Set a maximum width of 100% to make it adjust to the container */
      max-width: 100%;
    }
  }
  
  /* Media query for screens with a width between 500px and 768px (tablet devices) */
  @media (min-width: 500px) and (max-width: 767px) {
    video {
      /* Set a maximum width of 80% for larger mobile devices */
      max-width: 100%;
    }
  }
  
  /* Media query for screens with a width greater than or equal to 768px (desktop) */
  @media (min-width: 768px) {
    video {
      /* Set a maximum width of 500 pixels when the img width exceeds 500px */
      max-width: 100%;
    }
  }