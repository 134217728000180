.ql-syntax{
    background-color: black;
    color:white
  }
  .ql-editor .ql-video {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 50%;
  }
  .ql-editor .ql-text{
    color: aqua;
  }

  .ql-editor{
    font-family: "Arial";
  }
  .ql-font-courier {
    font-family: "courier";
  }
  
  .ql-font-arial {
    font-family: "Arial";
  }
  